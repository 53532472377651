import { mdiFileDocument, mdiBriefcase, mdiAccount, mdiCashLockOpen } from '@mdi/js'

export default [
  {
    title: 'Créditos',
    icon: mdiCashLockOpen,
    to: 'worker.credits'
  },
  {
    title: 'Proyectos',
    icon: mdiFileDocument,
    to: 'worker.projects'
  },
  {
    title: 'Cotizaciones',
    icon: mdiBriefcase,
    to: 'worker.quotations'
  },
  {
    title: 'Perfil',
    icon: mdiAccount,
    to: 'worker.account'
  }
]
