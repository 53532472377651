<template>
  <div
    v-if="!loadingCredits"
    class="wrapper-bartop-credit-balance bg-success"
    :class="{ danger: belowFifty }"
  >
    <div class="wrapper-text">
      ¡Hey!, actualmente tienes {{ totalCredits }} créditos disponibles,
      <router-link :to="{ name: 'worker.credits' }"
        >comprar créditos</router-link
      >
    </div>
  </div>
</template>

<script>
import { onMounted } from "@vue/composition-api";
import { useCredits } from "@/composables/credits";

export default {
  setup() {
    const { totalCredits, belowFifty, getWorkerCredits, loadingCredits } =
      useCredits();

    onMounted(() => {
      getWorkerCredits();
    });

    return {
      loadingCredits,
      totalCredits,
      belowFifty,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper-bartop-credit-balance {
  position: absolute;
  inset: 0px;
  padding: 8px 0px;
  color: rgb(255, 255, 255);
  height: fit-content;
  padding: 12px;
  text-align: center;
  background-color: var(--v-primary-base);
  margin-bottom: 2rem;

  &.danger {
    background-color: var(--v-error-base);
  }

  a {
    color: #fff !important;
  }
}
</style>
